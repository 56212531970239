export function formatPhoneNumber(phoneNumber: any) {
  // Remove all non-numeric characters
  if (!phoneNumber) {
    return '-'
  }
  const numericOnly = phoneNumber.replace(/\D/g, '')

  // Remove any leading digits (country code) and process the remaining digits
  const formattedPhoneNumber = numericOnly.replace(
    /^(\d*)(\d{3})(\d{3})(\d{4})/,
    (match: any, leadingDigits: any, first: any, second: any, third: any) => {
      let result = ''
      result += first + '-' + second + '-' + third
      return result
    },
  )

  return formattedPhoneNumber
}

// export function removeDialCode(phoneNumber: string) {
//   if (!phoneNumber) {
//     return '-'
//   }

//   // Remove only the +1 country code if present at the beginning
//   const formattedPhone = phoneNumber.replace(/^\+1/, '') // Remove +1
//   return formattedPhone.replace(/[^a-zA-Z0-9]/g, '') // Remove all non-alphanumeric characters (except letters)
// }

// export function removeDialCodeAndHyphen(phoneNumber: string) {
//   if (!phoneNumber) {
//     return '-'
//   }

//   // Remove only the +1 country code if present at the beginning
//   const formattedPhone = phoneNumber.replace(/^\+1/, '') // Remove +1
//   return formattedPhone.replace(/[^a-zA-Z0-9]/g, '') // Remove all non-alphanumeric characters (except letters)
// }
