import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Layout } from 'antd'
import { Input as AntInput } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { format } from 'date-fns'
import { MessageBox, ChatList, Input } from 'react-chat-elements'
import 'react-chat-elements/dist/main.css'
import './Chats.css'
import { useNavigate } from 'react-router-dom'
import styles from './Chat.module.scss'
import Loading from '../../../components/Loading/Loading'
import patientInfo from '../../../images/info.svg'
import noChatSvg from '../../../images/noChat.svg'
import noUserSvg from '../../../images/noUserFound.svg'
import SearchSvg from '../../../images/search.svg'
import sendArrow from '../../../images/sendArrow.svg'
import userSvg from '../../../images/user.svg'
import {
  getChatListAsync,
  getConversationAsync,
  resetChatListStatus,
  resetConversation,
  resetConversationStatus,
  SetCurrentChatId,
} from '../../../redux/admin/chat'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { StatusEnum } from '../../../redux/type'
import { formatPhoneNumber } from '../../../utils/FormatPhoneNumber'

const Chat = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // const chatEndRef: any = useRef(null)
  const chatList = useAppSelector((state) => state.admin.chat.chatList)
  const conversation = useAppSelector((state) => state.admin.chat.conversation)
  const chatListStatus = useAppSelector((state) => state.admin.chat.chatListStatus)
  const conversationStatus = useAppSelector((state) => state.admin.chat.conversationStatus)
  const currentChatId = useAppSelector((state) => state.admin.chat.currentChatId)
  const [searchTerm, setSearchTerm] = useState('')
  const [initialLoading, setInitialLoading] = useState(true)
  const [dataSource, setDataSource]: any = useState([])
  const [convo, setConvo]: any = useState([])
  const [filteredDatSource, setFilteredDataSource]: any = useState([])
  const [currentPatient, setCurrentPatient]: any = useState({
    id: '',
    conversationId: '',
    name: '',
    avatar: '',
  })
  const [inputValue, setInputValue] = useState('')

  const handleSearch = (e: any) => {
    const value = e.target.value
    setSearchTerm(value)
    if (value.trim() === '') {
      setFilteredDataSource(dataSource)
    } else {
      setFilteredDataSource(
        dataSource.filter((chat: any) => chat.nonFormattedTitle?.toLowerCase().includes(value?.toLowerCase())),
      )
    }
  }

  const formatDate = useCallback(
    (dateString: string) => {
      const messageDate = new Date(dateString)
      const today = new Date()
      const yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)

      if (messageDate.toDateString() === today.toDateString()) {
        return 'Today'
      } else if (messageDate.toDateString() === yesterday.toDateString()) {
        return 'Yesterday'
      } else {
        return format(messageDate, 'EEEE, d MMM, yyyy') // Default full date
      }
    },
    [conversation],
  )

  // const handleSend = () => {
  //   if (inputValue.trim()) {
  //     setMessages([...messages, { position: 'right', type: 'text', text: inputValue, date: new Date() }])
  //     setInputValue('')
  //   }
  // }

  // const scrollToBottom = () => {
  //   if (chatEndRef.current) {
  //     chatEndRef.current.scrollTop = chatEndRef.current.scrollHeight
  //   }
  // }

  // useEffect(() => {
  //   scrollToBottom()
  // }, [conversation, currentPatient])

  // async function getCurrentConversation() {
  //   const currentChatRes = await dispatch(getConversationAsync({ chatId: currentChatId })).unwrap()
  //   console.log(currentChatRes.data, 'currentChatRes')
  // }

  useEffect(() => {
    dispatch(getChatListAsync())
  }, [])

  useEffect(() => {
    if (chatList.length > 0) {
      const updatedChatList = chatList.map((m) => ({
        avatar: m.user.profilePic ? m.user.profilePic : userSvg,
        alt: 'profileImage',
        title: m.user.firstname ? m.user.firstname : formatPhoneNumber(m.user.phone),
        nonFormattedTitle: m.user.firstname ? m.user.firstname : m.user.phone,
        subtitle: '',
        date: '',
        unread: 0,
        id: m.id,
        patientId: m.user.id,
        className: '',
        statusColorType: 'bold',
      }))

      setDataSource(updatedChatList)
      setFilteredDataSource(updatedChatList)
    }
  }, [chatList])

  useEffect(() => {
    if (conversation.length > 0) {
      const updatedConversation = conversation.map((m) => ({
        position: m.convoRole === 'user' ? 'left' : 'right',
        type: 'text',
        text: m.content,
        date: m.createdAt,
      }))

      setConvo(updatedConversation)
    }
  }, [conversation])

  useEffect(() => {
    if (chatListStatus === StatusEnum.Success) {
      setInitialLoading(false)
    }
  }, [chatListStatus])

  useEffect(() => {
    // Set current chat as conversation if current chat is available
    if (currentChatId) {
      dispatch(getConversationAsync({ chatId: currentChatId }))
      if (chatList) {
        chatList.forEach((c: any) => {
          if (c.id === currentChatId) {
            setCurrentPatient({
              ...currentPatient,
              id: c.user.id || '',
              conversationId: c.id,
              name: c.user.firstname ? c.user.firstname : formatPhoneNumber(c.user.phone) || '',
              avatar: c.user.profilePic || userSvg,
            })
          }
        })
      }
    }
  }, [])

  useEffect(() => {
    if (currentChatId) {
      const classNameUpdatedList = filteredDatSource.map((chatItm: any) => ({
        ...chatItm,
        className: chatItm.id === currentChatId ? 'rceItemSelected' : '',
      }))
      setFilteredDataSource(classNameUpdatedList)
    }
  }, [dataSource])

  useEffect(() => {
    return () => {
      dispatch(resetChatListStatus())
      dispatch(resetConversationStatus())
    }
  }, [])

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        {initialLoading && <Loading style={{ background: 'none' }}></Loading>}
        {!initialLoading && (
          <div className={styles.chatContainer}>
            <div className={styles.sidebar}>
              <div>
                <AntInput
                  className={styles.searchBar}
                  placeholder="Search"
                  prefix={<img style={{ marginRight: '6px' }} alt="Search icon" src={SearchSvg} />}
                  value={searchTerm}
                  onChange={(e) => {
                    handleSearch(e)
                  }}
                  autoFocus
                ></AntInput>
                {filteredDatSource.length > 0 ? (
                  <ChatList
                    onClick={(e: any) => {
                      setCurrentPatient({
                        ...currentPatient,
                        id: e.patientId || '',
                        conversationId: e.id,
                        name: e.title || '',
                        avatar: e.avatar,
                      })
                      const classNameUpdatedList = dataSource.map((chatItm: any) => ({
                        ...chatItm,
                        className: chatItm.id === e.id ? 'rceItemSelected' : '',
                      }))
                      setDataSource(classNameUpdatedList)
                      if (e.id !== currentPatient.conversationId) {
                        setConvo([])
                        dispatch(SetCurrentChatId(e.id || null))
                        dispatch(getConversationAsync({ chatId: e.id || '' }))
                      }
                    }}
                    className={styles.chatList}
                    id="chat-list-1"
                    lazyLoadingImage=""
                    dataSource={filteredDatSource}
                  />
                ) : (
                  <div className={styles.noChatList}>
                    <div className={styles.noChatListBox}>
                      <img src={noUserSvg} alt="no chat" className={styles.noChatListImg}></img>
                      <div style={{ marginTop: 8, color: 'white' }}>No Users Found</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {conversationStatus === StatusEnum.Pending && (
              <div className={styles.chatWindowLoading}>
                <div className={styles.chatLoading}>
                  <div className={styles.loadingDot}></div>
                  <div className={styles.loadingDot}></div>
                  <div className={styles.loadingDot}></div>
                </div>
              </div>
            )}
            {currentPatient.id === '' && conversationStatus !== StatusEnum.Pending && (
              <div className={styles.noChatWindow}>
                <div className={styles.noChatBox}>
                  <img src={noChatSvg} alt="no chat" className={styles.noChatImg}></img>
                  <div style={{ marginTop: 8, color: 'white' }}>No Chat Selected</div>
                </div>
              </div>
            )}
            {currentPatient.id !== '' && conversationStatus !== StatusEnum.Pending && (
              <div className={styles.chatWindow}>
                <div className={styles.chatHeader}>
                  <div className={styles.leftSection}>
                    <img src={currentPatient.avatar} alt="avatar" className={styles.avatar} />
                    <span className={styles.chatTitle}>{currentPatient.name}</span>
                  </div>
                  <img
                    src={patientInfo}
                    alt="patient details"
                    className={styles.headerAvatar}
                    onClick={() => {
                      window.open(`/admin/patients/${currentPatient.id}`, '_blank', 'noopener,noreferrer')
                    }}
                  />
                </div>

                <div className={styles.chatMessages}>
                  {convo.map((msg: any, index: number) => {
                    const currentDate = new Date(msg.date).toDateString()
                    const previousDate = index > 0 ? new Date(convo[index - 1].date).toDateString() : null
                    const showDateSeparator = currentDate !== previousDate

                    return (
                      <React.Fragment key={index}>
                        {showDateSeparator && (
                          <div className={styles.dateSeparator}>
                            <div className={styles.leftLine}></div>
                            <span className={styles.dateText}>{formatDate(msg.date)}</span>
                            <div className={styles.rightLine}></div>
                          </div>
                        )}

                        <MessageBox
                          className={styles.messageBox}
                          position={msg.position}
                          date={msg.date}
                          id={index.toString()}
                          title={''}
                          retracted={false}
                          focus={false}
                          forwarded={false}
                          removeButton={false}
                          replyButton={false}
                          titleColor=""
                          notch={false}
                          status={msg.position}
                          type={'text'}
                          text={msg.text}
                        />
                      </React.Fragment>
                    )
                  })}
                </div>

                <div className={styles.chatInput}>
                  <AntInput
                    disabled
                    placeholder={'Message'}
                    className={styles.messageInput}
                    value={inputValue}
                    onChange={(e: any) => setInputValue(e.target.value)}
                    style={{ border: 'none' }}
                    suffix={
                      <Button
                        className={styles.sendButton}
                        disabled
                        onClick={() => {}}
                        children={<img src={sendArrow} alt={'arrow'} style={{ height: 19, width: 19 }}></img>}
                      ></Button>
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Content>
    </Layout>
  )
}

export default Chat
