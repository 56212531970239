import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIResponse } from '../../../services/interface'
import { ApiGet } from '../../../services/Service'
import { StatusEnum } from '../../type'

interface Appointment {
  appointmentTime: string
  needsFullSlot: boolean
  count: string
  isMember: boolean
}
interface ScheduleState {
  schedule: Array<Appointment>
  scheduleStatus: StatusEnum
}
const initialState: ScheduleState = {
  schedule: [],
  scheduleStatus: StatusEnum.Idle,
}

export const getScheduleByWeek = createAsyncThunk(
  'schedule/getScheduleByWeek',
  async ({ startDate, endDate, clinicId }: { startDate: any; endDate: any; clinicId: any }, thunkAPI) => {
    const response: APIResponse = await ApiGet(
      `/clinic/schedule?startDate=${startDate}&endDate=${endDate}&clinicId=${clinicId}`,
    )
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    resetSchedule: (state) => {
      state.schedule = []
    },
  },
  extraReducers(builder) {
    //getScheduleByWeek
    builder.addCase(getScheduleByWeek.fulfilled, (state, action) => {
      state.schedule = action.payload.data
      state.scheduleStatus = StatusEnum.Success
    })
    builder.addCase(getScheduleByWeek.pending, (state, action) => {
      state.scheduleStatus = StatusEnum.Pending
    })
    builder.addCase(getScheduleByWeek.rejected, (state, action) => {
      state.scheduleStatus = StatusEnum.Failed
    })
  },
})

export const { resetSchedule } = scheduleSlice.actions

export default scheduleSlice.reducer
