import { useEffect, useMemo, useState } from 'react'
import { Button, DatePicker, Drawer, Form, Input, Layout, Select, Space } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './Doctor.module.scss'
import Loading from '../../../components/Loading/Loading'
import Table, { IColumn, TableRow } from '../../../components/Table'
import Close from '../../../images/cross.svg'
import Arrow from '../../../images/dropArrow.svg'
import { getDoctorsAsync, postDoctorAsync, resetPostDoctorStatus } from '../../../redux/admin/doctor'
import { getStudiosAsync } from '../../../redux/admin/studio'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { StatusEnum } from '../../../redux/type'
// import { calculateDob } from '../../../utils/Dob'
import { formatPhoneNumber } from '../../../utils/FormatPhoneNumber'
import { NotificaitonPlacement, notifyError, notifySuccess } from '../../../utils/Notification'

export const Doctors = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const doctors = useAppSelector((state) => state.admin.doctor.doctors)
  const doctorsMetaData: any = useAppSelector((state) => state.admin.doctor.doctorsMetaData)
  const doctorStatus = useAppSelector((state) => state.admin.doctor.doctorsStatus)
  const apiError = useAppSelector((state) => state.admin.doctor.error)
  const [open, setOpen] = useState(false)

  const columns: Array<IColumn> = [
    {
      capitalize: true,
      displayName: 'First Name',
      key: 'firstname',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Last Name',
      key: 'lastname',
      size: 30,
    },
    // {
    //   capitalize: true,
    //   displayName: 'Age',
    //   key: 'age',
    //   size: 30,
    // },
    {
      capitalize: false,
      displayName: 'Phone',
      key: 'phone',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Email',
      key: 'email',
      size: 30,
    },
    // {
    //   capitalize: true,
    //   displayName: 'Studio',
    //   key: 'studio',
    //   size: 30,
    // },
    {
      capitalize: false,
      displayName: 'Active',
      key: 'active',
    },
  ]

  const rows: Array<TableRow> = useMemo(() => {
    return doctors.map((d) => {
      return {
        id: d.id || '',
        firstname: d.user?.firstname || '',
        lastname: d.user?.lastname || '',
        // age: calculateDob(d.user?.dob) || '',
        phone: formatPhoneNumber(d.user?.phone) || '',
        email: d.user?.email || '',
        // studio: d?.clinic?.name || '',
        active: d?.isActive ? 'Yes' : 'No',
      }
    })
  }, [doctors])

  useEffect(() => {
    dispatch(
      getDoctorsAsync({
        currentPage: 1,
        pageSize: 20,
      }),
    )
  }, [])

  useEffect(() => {
    if (doctorStatus === StatusEnum.Failed) {
      notifyError('Unable to fetch doctors', apiError)
    } else if (doctorStatus === StatusEnum.Success) {
    }
  }, [doctorStatus])

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content>
        <div style={{ padding: 20, height: doctorsMetaData?.totalRecordCount > 20 ? '96%' : '99%' }}>
          {doctorStatus === StatusEnum.Pending && <Loading style={{ background: 'none' }}></Loading>}

          {doctorStatus === StatusEnum.Success && (
            <Table
              columns={columns}
              rows={rows}
              buttonText="New Doctor"
              totalPageCount={doctorsMetaData?.totalRecordCount}
              currentPage={doctorsMetaData?.currentPage}
              onRowClick={(id) => {
                navigate(`/admin/doctors/${id}`)
              }}
              onButtonClick={() => {
                setOpen(true)
              }}
              onPageChange={(page, pageSize) => {
                console.log(page, pageSize)
                dispatch(
                  getDoctorsAsync({
                    pageSize: pageSize,
                    currentPage: page,
                  }),
                )
              }}
              initalButtonChildren={'Create Doctor'}
              onInitialButtonClicked={() => {
                setOpen(true)
              }}
            ></Table>
          )}
        </div>
        <Drawer
          classNames={styles}
          title="New Doctor"
          closable={false}
          extra={
            <Space>
              <img
                onClick={() => {
                  setOpen(false)
                }}
                src={Close}
                alt="CloseSvg"
                style={{ height: '25px', width: '25px', marginTop: '6px', cursor: 'pointer' }}
              ></img>
            </Space>
          }
          open={open}
        >
          {open && (
            <AddDoctor
              onClose={() => {
                setOpen(false)
              }}
              onCreate={() => {
                setOpen(false)
              }}
            ></AddDoctor>
          )}
        </Drawer>
      </Content>
    </Layout>
  )
}
interface AddDoctorProps {
  onClose: () => void
  onCreate?: () => void
}
export interface ICreateDoctorDto {
  firstname: string
  lastname: string
  // dob: string
  phone: string
  email: string
  // clinicId: string
  isActive?: boolean
}
export const AddDoctor = ({ onClose, onCreate }: AddDoctorProps) => {
  const dispatch = useAppDispatch()
  // const studios = useAppSelector((state) => state.admin.studio.studios)
  // const studioOptions = studios?.map((s) => ({ label: s?.name, value: s?.id })) || {}
  const doctorDtoStatus = useAppSelector((state) => state.admin.doctor.postDoctorStatus)
  const apiError = useAppSelector((state) => state.admin.doctor.error)
  const [form] = Form.useForm()

  // useEffect(() => {
  //   dispatch(getStudiosAsync({}))
  // }, [])

  useEffect(() => {
    if (doctorDtoStatus === StatusEnum.Failed) {
      notifyError('Unable to create doctor', apiError, NotificaitonPlacement.bottomLeft)
    } else if (doctorDtoStatus === StatusEnum.Success) {
      notifySuccess('Success', 'Doctor created successfully')
      onCreate && onCreate()
      dispatch(
        getDoctorsAsync({
          currentPage: 1,
          pageSize: 20,
        }),
      )
    }
    return () => {
      dispatch(resetPostDoctorStatus())
    }
  }, [doctorDtoStatus])

  const onFinish = (values: any) => {
    values.phone = `+1${values.phone.replace(/[^0-9]/g, '')}`
    dispatch(postDoctorAsync({ data: values }))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const onCancel = () => {
    form.resetFields()
    onClose && onClose()
  }

  const disabledDate = (current: any) => {
    return current && dayjs(current).isAfter(dayjs().endOf('day'))
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <Form
          labelWrap={true}
          form={form}
          name="basic"
          layout="vertical"
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          autoComplete="off"
        >
          <Form.Item<ICreateDoctorDto>
            label="First Name"
            name="firstname"
            rules={[{ required: true, message: 'Please input first name!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter First name" />
          </Form.Item>

          <Form.Item<ICreateDoctorDto>
            label="Last Name"
            name="lastname"
            rules={[{ required: true, message: 'Please input last name!' }]}
          >
            <Input style={{ width: 317, textTransform: 'capitalize' }} placeholder="Enter Last name" />
          </Form.Item>

          {/* <Form.Item<ICreateDoctorDto>
            label="Date of Birth"
            name="dob"
            rules={[{ required: true, message: 'Please input dob!' }]}
          >
            <DatePicker
              format={{
                format: 'MM-DD-YYYY',
                type: 'mask',
              }}
              placeholder="MM/DD/YYYY"
              style={{ height: '3.8rem', width: 317 }}
              suffixIcon={false}
              disabledDate={disabledDate}
            ></DatePicker>
          </Form.Item> */}

          <Form.Item<ICreateDoctorDto>
            label="Phone"
            name="phone"
            rules={[{ required: true, message: 'Please input phone!', min: 12 }]}
          >
            <Input
              style={{ width: 317 }}
              placeholder="Enter Phone Number"
              onChange={(e: any) => {
                const formattedValue = e.target.value
                  .replace(/\D/g, '')
                  .slice(0, 10)
                  .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_: any, first: any, second: any, third: any) => {
                    let result = first
                    if (second) result += '-' + second
                    if (third) result += '-' + third
                    return result
                  })
                form.setFieldValue('phone', formattedValue)
              }}
            />
          </Form.Item>

          <Form.Item<ICreateDoctorDto>
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input email!' }]}
          >
            <Input style={{ width: 317 }} placeholder="Enter Email Address" />
          </Form.Item>

          {/* <Form.Item<ICreateDoctorDto>
            label="Studio"
            name="clinicId"
            rules={[{ required: true, message: 'Please input studio!' }]}
          >
            <Select
              notFoundContent="No studio available"
              style={{ width: 317, textTransform: 'capitalize' }}
              dropdownStyle={{ textTransform: 'capitalize' }}
              placeholder={'Select Studio'}
              options={studioOptions}
              suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
            ></Select>
          </Form.Item> */}

          <Form.Item wrapperCol={{ span: 16 }}>
            <div className={styles.footer}>
              <Button className={styles.button} children="Cancel" type="default" onClick={onCancel}></Button>
              <Button className={styles.button} children="Submit" htmlType="submit" type="primary"></Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
