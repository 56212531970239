import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { APIResponse } from '../../../services/interface'
import { ApiGet } from '../../../services/Service'
import { StatusEnum } from '../../type'

export interface Conversation {
  id: string
  content: string
  convoRole: string
  createdAt: string
  updatedAt: string
}

interface ChatItem {
  id: string
  gptThreadId: string
  createdAt: string
  updatedAt: string
  user: {
    id: string
    firstname: string
    signupThrough: string
    referralCode: string | null
    lastname: string | null
    phone: string
    unverifiedPhone: string | null
    dob: string | null
    email: string | null
    unverifiedEmail: string | null
    externalUserId: string | null
    emailVerificationId: string
    welcomeEmailSend: boolean
    textConsent: boolean
    profilePic: string | null
    screeningStatus: string
    gender: string | null
    isDeleted: boolean
    createdAt: string
    updatedAt: string
  }
}

export interface ChatState {
  chatList: Array<ChatItem>
  conversation: Array<Conversation>
  chatListStatus: StatusEnum
  conversationStatus: StatusEnum
  currentChatId: string | null
}

const initialState: ChatState = {
  chatList: [],
  conversation: [],
  chatListStatus: StatusEnum.Idle,
  conversationStatus: StatusEnum.Idle,
  currentChatId: null,
}

export const getChatListAsync = createAsyncThunk('chat/getChatListAsync', async (_, thunkAPI: any) => {
  const response: APIResponse = await ApiGet(`/chat`)
    .then((res) => res)
    .catch((err) => err)

  if (!response.status) {
    return thunkAPI.rejectWithValue('Network error. Please check your internet.')
  } else if (response.data && response.data.code === 'success') {
    return thunkAPI.fulfillWithValue({
      data: response.data.data,
    })
  } else {
    return thunkAPI.rejectWithValue('Network error. Please check your internet.')
  }
})

export const getConversationAsync = createAsyncThunk(
  'chat/getConversationAsync',
  async ({ chatId }: { chatId: any }, thunkAPI: any) => {
    const response: APIResponse = await ApiGet(`/chat/conversation/${chatId}`)
      .then((res) => res)
      .catch((err) => err)

    if (!response.status) {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    } else if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
      })
    } else {
      return thunkAPI.rejectWithValue('Network error. Please check your internet.')
    }
  },
)

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    SetCurrentChatId: (state, action) => {
      state.currentChatId = action.payload
    },
    resetConversation: (state) => {
      state.conversation = []
    },
    resetChatListStatus: (state) => {
      state.chatListStatus = StatusEnum.Idle
    },
    resetConversationStatus: (state) => {
      state.conversationStatus = StatusEnum.Idle
    },
  },
  extraReducers(builder) {
    //getChatListAsync
    builder.addCase(getChatListAsync.pending, (state) => {
      state.chatListStatus = StatusEnum.Pending
    })
    builder.addCase(getChatListAsync.fulfilled, (state, action) => {
      state.chatList = action.payload.data
      state.chatListStatus = StatusEnum.Success
    })
    builder.addCase(getChatListAsync.rejected, (state) => {
      state.chatListStatus = StatusEnum.Failed
    })

    //getChatListAsync
    builder.addCase(getConversationAsync.pending, (state) => {
      state.conversationStatus = StatusEnum.Pending
    })
    builder.addCase(getConversationAsync.fulfilled, (state, action) => {
      state.conversation = action.payload.data
      state.conversationStatus = StatusEnum.Success
    })
    builder.addCase(getConversationAsync.rejected, (state) => {
      state.conversationStatus = StatusEnum.Failed
    })
  },
})

export default chatSlice.reducer

export const { resetConversation, resetChatListStatus, resetConversationStatus, SetCurrentChatId } = chatSlice.actions
