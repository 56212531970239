import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Spin, Carousel, Layout, Select } from 'antd'
import type { CarouselRef } from 'antd/es/carousel'
import { Content, Header } from 'antd/es/layout/layout'
import { format, addDays, startOfWeek, getHours } from 'date-fns'
import styles from './Schedule.module.scss'
import leftArrow from '../../../images/arrowLeft.svg'
import rightArrow from '../../../images/arrowRight.svg'
import Arrow from '../../../images/dropArrow.svg'
import { getScheduleByWeek } from '../../../redux/admin/schedule'
import { getStudiosAsync } from '../../../redux/admin/studio'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { notifyError, notifySuccess } from '../../../utils/Notification'

const Schedule = () => {
  const dispatch = useAppDispatch()
  const studios = useAppSelector((state) => state.admin.studio.studios)
  const [selectedStudio, setSelectedStudio] = useState({ label: '', value: '' })
  const studioOptions = studios?.map((s) => ({ label: s?.name, value: s?.id })) || []
  const TOTAL_WEEKS = 2
  const [centralWeekIndex, setCentralWeekIndex] = useState(0)
  const [weekStarts, setWeekStarts] = useState<Date[]>([])
  const [loading, setLoading] = useState(true)
  const [calendarData, setCalendarData]: any = useState({})
  const carouselRef = useRef<CarouselRef>(null)

  const timeSlots = [
    { label: '8 AM', value: 8 },
    { label: '9 AM', value: 9 },
    { label: '10 AM', value: 10 },
    { label: '11 AM', value: 11 },
    { label: '12 PM', value: 12 },
    { label: '1 PM', value: 13 },
    { label: '2 PM', value: 14 },
    { label: '3 PM', value: 15 },
    { label: '4 PM', value: 16 },
    { label: '5 PM', value: 17 },
    { label: '6 PM', value: 18 },
    { label: '7 PM', value: 19 },
    { label: '8 PM', value: 20 },
  ]

  const handlePrevWeek = useCallback(() => {
    if (centralWeekIndex > 0) {
      carouselRef?.current?.prev()
    }
  }, [centralWeekIndex])

  const handleNextWeek = useCallback(() => {
    if (centralWeekIndex < TOTAL_WEEKS - 1) {
      carouselRef?.current?.next()
    }
  }, [centralWeekIndex, TOTAL_WEEKS])

  const getCurrentWeekMonday = useCallback(() => {
    const today = new Date()
    return startOfWeek(today, { weekStartsOn: 1 }) // ISO week starts on Monday
  }, [])

  const generateDays = useCallback((weekStart: Date) => {
    const days = []

    for (let i = 0; i < 7; i++) {
      const day = addDays(weekStart, i)
      days.push({
        date: day,
        dayName: format(day, 'EEE').toUpperCase(),
        dayNumber: format(day, 'd'),
      })
    }

    return days
  }, [])

  const headerDays = weekStarts.length > 0 ? generateDays(weekStarts[centralWeekIndex]) : []

  const handleCarouselChange = useCallback(
    (currentSlide: number) => {
      if (currentSlide !== centralWeekIndex) {
        setCalendarData({})
        setCentralWeekIndex(currentSlide)
      }
    },
    [centralWeekIndex],
  )

  const assignColor = useCallback((isMember: any, needsFullSlot: any, c: any) => {
    const count = parseInt(c)

    if (count === 1) {
      return !isMember && needsFullSlot ? '#d2d3e0' : '#007f7c'
    }

    return '#007f7c'
  }, [])

  const assignOpacity = useCallback((c: any) => {
    const count = parseInt(c)

    if (count === 1) {
      return 1
    } else if (count === 2) {
      return 0.75
    } else if (count >= 3) {
      return 0.5
    }

    return ''
  }, [])

  const convertUtcToEst = useCallback((utcString: string): string => {
    const dateUTC = new Date(utcString)

    // Format to Eastern Time (New York)
    const options: any = {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }
    return dateUTC.toLocaleString('en-US', options)
  }, [])

  const transformDataForCalendar = useCallback((data: any[]) => {
    return data.reduce(
      (acc, item) => {
        const date = new Date(convertUtcToEst(item.appointmentTime))
        const dayLabel = format(date, 'EEE').toUpperCase()
        const hour = getHours(date)
        const minutes = date.getMinutes()
        const quarter = Math.floor(minutes / 15) // 0 => 00, 1 => 15, 2 => 30, 3 => 45

        if (hour >= 8 && hour < 20) {
          if (!acc[dayLabel]) {
            acc[dayLabel] = {}
          }
          if (!acc[dayLabel][hour]) {
            // Initialize an array for the four quarter slots
            acc[dayLabel][hour] = [[], [], [], []]
          }

          acc[dayLabel][hour][quarter].push({
            id: item.appointmentTime,
            color: assignColor(item.isMember, item.needsFullSlot, item.count),
            opacity: assignOpacity(item.count),
            outlined: item.isMember && item.needsFullSlot ? true : false,
            count: item.count,
            isMember: item.isMember,
            date: format(date, 'yyyy-MM-dd'),
          })
        }

        return acc
      },
      {} as Record<string, Record<number, any[][]>>,
    )
  }, [])

  useEffect(() => {
    const currentWeekStart = getCurrentWeekMonday()
    const weeks = []

    for (let i = 0; i < TOTAL_WEEKS; i++) {
      weeks.push(addDays(currentWeekStart, i * 7))
    }

    setWeekStarts(weeks)
  }, [])

  useEffect(() => {
    const fetchStudios = async () => {
      try {
        const res = await dispatch(getStudiosAsync({})).unwrap()
        if (res?.data && res?.data?.length > 0) {
          setSelectedStudio({ label: res.data[0]?.name, value: res.data[0]?.id })
        } else {
          if (res?.data?.length === 0) {
            notifySuccess('No studios found', '')
          }
          setLoading(false)
        }
      } catch (err) {
        notifyError('Unable to fetch Schedule', '')
        setLoading(false)
      }
    }

    fetchStudios()
  }, [])

  useEffect(() => {
    const fetchSchedule = async () => {
      if (weekStarts.length > 0 && selectedStudio.value) {
        setLoading(true)
        try {
          const formattedStartDate = format(weekStarts[centralWeekIndex], 'yyyy-MM-dd HH:mm:ss')
          const formattedEndDate = format(addDays(weekStarts[centralWeekIndex], 6), 'yyyy-MM-dd HH:mm:ss')
          const res = await dispatch(
            getScheduleByWeek({
              startDate: formattedStartDate,
              endDate: formattedEndDate,
              clinicId: selectedStudio.value,
            }),
          ).unwrap()
          if (res?.data) {
            const uniqueData = await res?.data.filter(
              (item: any, index: any, self: any) =>
                index === self.findIndex((t: any) => t.appointmentTime === item.appointmentTime),
            )

            const calendarData = transformDataForCalendar(uniqueData)
            const formattedDate = format(weekStarts[centralWeekIndex], 'yyyy-MM-dd')
            setCalendarData((prevData: any) => ({
              ...prevData,
              [formattedDate]: calendarData,
            }))
          }
          setLoading(false)
        } catch (err) {
          notifyError('Unable to fetch Schedule', '')
          setLoading(false)
        }
      }
    }

    fetchSchedule()
  }, [weekStarts, centralWeekIndex, selectedStudio])

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}>
        <Select
          notFoundContent="No studio available"
          className={styles.selectStudio}
          value={selectedStudio}
          style={{ textTransform: 'capitalize' }}
          dropdownStyle={{ textTransform: 'capitalize' }}
          placeholder={'Select Studio'}
          options={studioOptions}
          onChange={(o, v: any) => {
            setSelectedStudio({ label: v.label, value: v.value })
            setLoading(true)
            setCentralWeekIndex(0)
          }}
          suffixIcon={<img src={Arrow} alt="arrowDown" style={{ marginTop: '0.3rem' }} />}
        ></Select>

        <div
          style={{
            position: 'absolute',
            right: 40,
            top: 12,
            display: 'flex',
            gap: '10px',
          }}
        >
          <button
            onClick={handlePrevWeek}
            disabled={centralWeekIndex === 0}
            className={styles.paginationArrows}
            style={{
              cursor: centralWeekIndex === 0 ? 'not-allowed' : 'pointer',
              opacity: centralWeekIndex === 0 ? 0.5 : 1,
            }}
          >
            <img src={leftArrow} alt="<" style={{ width: '12px', height: '12px' }} />
          </button>
          <button
            onClick={handleNextWeek}
            disabled={centralWeekIndex === TOTAL_WEEKS - 1}
            className={styles.paginationArrows}
            style={{
              cursor: centralWeekIndex === TOTAL_WEEKS - 1 ? 'not-allowed' : 'pointer',
              opacity: centralWeekIndex === TOTAL_WEEKS - 1 ? 0.5 : 1,
            }}
          >
            <img src={rightArrow} alt=">" style={{ width: '12px', height: '12px' }} />
          </button>
        </div>
      </Header>
      <Content>
        <div className={styles.calendarContainer}>
          <>
            <div className={styles.fixedHeaderSection}>
              <div className={styles.emptyHeaderCell}></div>
              <div className={styles.headerRow}>
                {headerDays?.map((day, index) => (
                  <div className={styles.headerCell} key={index}>
                    <div style={{ marginBottom: '4px', fontWeight: 700 }}>{day.dayNumber}</div>
                    <div style={{ fontWeight: 600 }}>{day.dayName}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.mainContentContainer}>
              <div className={styles.timeColumnContainer}>
                {timeSlots?.map((timeSlot, index) => (
                  <div className={styles.timeCell} key={index}>
                    <div className={styles.time}>{timeSlot.label}</div>
                  </div>
                ))}
              </div>
              {loading ? (
                <div className={styles.loadingContainer}>
                  <Spin size="large" />
                </div>
              ) : (
                <div className={styles.carouselContainer}>
                  <Carousel
                    className={styles.styledCarousel}
                    ref={carouselRef}
                    dots={false}
                    infinite={false}
                    speed={500}
                    beforeChange={(_, next) => handleCarouselChange(next)}
                    initialSlide={centralWeekIndex}
                    swipeToSlide
                    draggable
                    swipe
                  >
                    {weekStarts?.map((weekStart, index) => (
                      <div key={index}>
                        <div className={styles.scrollableGridContainer}>
                          <CalendarWeek
                            weekStart={weekStart}
                            calendarData={calendarData[format(weekStart, 'yyyy-MM-dd')] || {}}
                            timeSlots={timeSlots}
                          />
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              )}
            </div>
          </>
        </div>
      </Content>
    </Layout>
  )
}

export default Schedule

interface EventBarProps {
  count: number
  color: string
  outlined?: boolean
  opacity: number
}

const EventBar: React.FC<EventBarProps> = ({ count, color, outlined, opacity }) => {
  let barWidth = '100%'

  if (count === 1) {
    barWidth = '100%'
  } else if (count === 2) {
    barWidth = '50%'
  } else if (count === 3) {
    barWidth = '33.33%'
  }

  return (
    <div style={{ display: 'flex', gap: '2px', width: '100%', marginTop: '0.5px' }}>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          style={{
            width: barWidth,
            height: '11.5px',
            background: color,
            outline: outlined ? '1px dashed #d2d3e0' : 'none',
            outlineOffset: '-1px',
            opacity: opacity,
            borderRadius: '2px',
            gap: 2,
          }}
        />
      ))}
    </div>
  )
}

interface CalendarWeekProps {
  weekStart: Date
  calendarData: any
  timeSlots: { label: string; value: number }[]
}

const CalendarWeek: React.FC<CalendarWeekProps> = ({ weekStart, calendarData, timeSlots }) => {
  const generateDays = useCallback(() => {
    const days = []
    for (let i = 0; i < 7; i++) {
      const day = addDays(weekStart, i)
      days.push({
        date: day,
        dayName: format(day, 'EEE').toUpperCase(),
        dayNumber: format(day, 'd'),
      })
    }

    return days
  }, [weekStart])

  const [days, setDays] = useState(generateDays())

  const getEventsForQuarter = useCallback(
    (dayLabel: any, time: any, quarter: number) => {
      return (calendarData?.[dayLabel]?.[time] && calendarData[dayLabel][time][quarter]) || []
    },
    [calendarData],
  )

  return (
    <div className={styles.calendarGrid}>
      {timeSlots?.map((timeSlot, index) => (
        <React.Fragment key={index}>
          {days.map((day, dayIndex) => (
            <div className={styles.calendarCell} key={dayIndex}>
              {[0, 1, 2, 3].map((quarter) => {
                const events = getEventsForQuarter(day.dayName, timeSlot.value, quarter)
                return (
                  <div className={styles.quarterRow} key={quarter}>
                    {events.map((event: any, idx: any) => (
                      <EventBar
                        key={idx}
                        count={event.count}
                        color={event.color}
                        opacity={event.opacity}
                        outlined={event.outlined}
                      />
                    ))}
                  </div>
                )
              })}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}
